import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconBuyerProtectionProps {
  className?: string;
  color?: string;
}

const IconBuyerProtection: FC<IconBuyerProtectionProps> = (props) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 6.50428V16.1503C7 17.7502 7.75017 19.2576 9.02651 20.2223L14.926 24.6812C15.5614 25.1615 16.4386 25.1615 17.074 24.6812L22.9735 20.2223C24.2498 19.2576 25 17.7502 25 16.1503V6.50428L24.1198 6.70387C18.7744 7.91592 13.2256 7.91592 7.88022 6.70387L7 6.50428ZM8.32249 4.75339C13.3767 5.89941 18.6233 5.89941 23.6775 4.75339L25.0411 4.4442C26.0447 4.21663 27 4.97955 27 6.00865V16.1503C27 18.3771 25.9559 20.4751 24.1794 21.8178L18.28 26.2767C16.931 27.2964 15.069 27.2964 13.72 26.2767L7.82057 21.8178C6.04411 20.4751 5 18.3771 5 16.1503V6.00865C5 4.97955 5.95528 4.21663 6.9589 4.4442L8.32249 4.75339Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7242 12.3793L14.3334 20.1397L10.2759 15.8793L11.7242 14.5L14.3334 17.2397L20.2759 11L21.7242 12.3793Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconBuyerProtection;
