/* eslint-disable import/prefer-default-export */
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { types as sdkTypes } from '../../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../../util/urlHelpers';
import { ensureListing, ensureOwnListing } from '../../../util/data';
import { getMarketplaceEntities } from '../../../ducks/marketplaceData.duck';

const { UUID } = sdkTypes;

export const useCurrentListing = () => {
  const params = useParams() as any;

  const listingId = new UUID(params.id);
  const isPendingApprovalVariant = params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = params.variant === LISTING_PAGE_DRAFT_VARIANT;
  const isPendingApprovalOrDraft = isPendingApprovalVariant || isDraftVariant;

  const { currentUser } = useSelector<any>((state) => state.user) as any;
  const listing = useSelector((state) => {
    const ref = { id: listingId, type: isPendingApprovalOrDraft ? 'ownListing' : 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  });

  const currentListing = isPendingApprovalOrDraft
    ? ensureOwnListing(listing)
    : ensureListing(listing);

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  return { currentListing, isOwnListing };
};
