/* eslint-disable import/prefer-default-export */
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { IconGemFilled, TypographyWrapper } from '../../components';
import {
  TypographyFormat,
  TypographyWeight,
} from '../../components/TypographyWrapper/TypographyWrapper';
import { ModalType, setActiveModal } from '../../ducks/modal.duck';
import { useRouteConfiguration } from '../../hooks/useRouteConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { Listing } from '../../types/sharetribe/listing';
import { createSlug } from '../../util/urlHelpers';
import { setSavedSearchSource } from '../../ducks/user.duck';
import { EmailSubscribeSource } from '../../types/apollo/generated/types.generated';
import css from './ListingPage.module.css';

interface SectionListingISOProps {
  title: string;
  currentListing: Listing;
  className?: string;
}

export const SectionListingISO: FC<SectionListingISOProps> = (props) => {
  const { title, currentListing, className } = props;
  const dispatch = useDispatch();
  const routes = useRouteConfiguration();
  const history = useHistory();

  const handleISOBannerClick = () => {
    const to = createResourceLocatorString(
      'ListingPage',
      routes,
      {
        slug: createSlug(currentListing?.attributes?.title || ''),
        id: currentListing?.id?.uuid,
      },
      {
        productId: currentListing?.attributes?.publicData?.shopifyProductId,
      }
    );
    history.replace(to);
    dispatch(setActiveModal(ModalType.ISO));
    dispatch(setSavedSearchSource({ source: EmailSubscribeSource.ListingPageIso }));
  };

  return (
    <Box className={classNames(className, css.sectionIso)} onClick={handleISOBannerClick}>
      <Box display="flex" flexDirection="row" width="100%">
        <Box display="flex" alignItems="center" pr={3}>
          <IconGemFilled className={css.sectionIcon} />
        </Box>
        <Box display="flex" flexDirection="column" mr={2}>
          <TypographyWrapper variant="body2" weight={TypographyWeight.Bold}>
            {title}
          </TypographyWrapper>
          <TypographyWrapper variant="body2" format={TypographyFormat.Underlined}>
            Submit an item request
          </TypographyWrapper>
        </Box>
      </Box>
    </Box>
  );
};
