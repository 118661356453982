import React, { FC, ReactNode } from 'react';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import { IconArrowRight, TypographyWrapper } from '../../../components';
import css from './SectionConsolidatedListings.module.css';

interface SectionConsolidatedListingsLinkRowProps {
  icon: ReactNode;
  description: string;
  onClick?: () => void;
}

const SectionConsolidatedListingsLinkRow: FC<SectionConsolidatedListingsLinkRowProps> = (
  props: SectionConsolidatedListingsLinkRowProps
) => {
  const { icon, description, onClick } = props;

  return (
    <Box
      onClick={onClick}
      className={classNames(
        css.consolidatedTableRow,
        css.sectionConsolidatedListingsLinkRowWrapper
      )}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" flexDirection="row" alignItems="center" width="100%">
          <Box display="flex" alignItems="center" pr={{ xs: 2, lg: '12px' }}>
            {icon}
          </Box>
          <TypographyWrapper variant="body2">{description}</TypographyWrapper>
        </Box>
        <IconArrowRight />
      </Box>
    </Box>
  );
};

export default SectionConsolidatedListingsLinkRow;
