/* eslint-disable import/prefer-default-export */
import { find } from 'lodash';
import { useShopConfig } from '../../../hooks/shopConfig';
import { Listing } from '../../../types/sharetribe/listing';
import { sanitizeSpaces } from '../../../util/strings';
import { useCurrentListing } from './useCurrentListing';

export const useSellerNotes = (listingFromProps?: Listing) => {
  let listing = listingFromProps;

  if (!listing) {
    const { currentListing } = useCurrentListing();
    listing = currentListing;
  }

  const { tagToSellerNotes = {} } = useShopConfig();

  const { publicData } = listing?.attributes || {};
  const { conditionInfo, tags = [] } = publicData;
  const { notes: rawNotes } = conditionInfo || {};

  const notesSuffix = find(tagToSellerNotes, (text, configTag) => {
    const hasMatchingTag = tags.find(
      (listingTag: string) => listingTag.toLowerCase().trim() === configTag.toLowerCase().trim()
    );
    return hasMatchingTag ? text : false;
  });

  const notes = `${rawNotes ?? ''}\n\n${notesSuffix ?? ''}`.trim();

  return { hasNoteSuffix: !!notesSuffix, notes: sanitizeSpaces(notes) };
};
