import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconRepresentLoyaltyProps {
  className?: string;
  color?: string;
}

const IconRepresentLoyalty: FC<IconRepresentLoyaltyProps> = (props: IconRepresentLoyaltyProps) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
      width="20"
      height="13"
      viewBox="0 0 20 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="19" height="12" rx="1.5" stroke="#000000" fill="none" />
      <path
        d="M9.09955 5.52779L11.4251 9.92723C11.447 9.9771 11.469 9.99627 11.524 9.99627L12.8643 10.0001C12.8863 10.0001 12.8973 9.97329 12.8826 9.95411C12.7288 9.78149 12.4395 9.27905 12.4395 9.27905L11.209 6.98534C11.22 6.98149 11.2346 6.97767 11.2456 6.97382C11.8609 6.77052 12.3772 6.42148 12.7544 5.86532C13.0474 5.43191 13.1975 4.95245 13.139 4.41547C13.1024 4.06643 12.9522 3.77876 12.7032 3.54862C12.5237 3.37985 12.3113 3.26861 12.0806 3.18425C11.773 3.07301 11.4251 3.04232 11.0881 3.027C10.8061 3.01168 10.5534 3.00397 10.3081 3.00397C10.1396 3.00397 8.11808 3.00012 7.79581 3.00012C7.78118 3.00012 7.77018 3.0193 7.78118 3.03081C7.89838 3.16123 8.3195 3.6522 8.24995 3.94367C8.18403 4.2812 8.14374 4.45763 8.14374 4.45763C8.14374 4.45763 7.85077 5.78858 7.67864 6.55955L7.32122 8.34464C7.32122 8.34505 7.32083 8.34581 7.32083 8.34617L7.28824 8.50956L7.17471 9.07799L7.15786 9.16316L7.00075 9.9495C6.996 9.97365 7.01358 9.99667 7.03736 9.99667H8.39385C8.40484 9.99667 8.43046 10.0005 8.43046 9.98135C8.43414 9.95832 8.37186 9.9008 8.33525 9.8586C8.2254 9.73584 8.26822 9.54754 8.29752 9.35956C8.29752 9.35956 8.35244 9.09492 8.38174 8.94914L8.48979 8.42215L8.51614 8.29402L8.73699 7.21929C8.73699 7.21929 9.06657 5.76174 9.09955 5.52779ZM9.39983 3.60614C9.41083 3.60229 9.42546 3.60229 9.45112 3.60229H10.6926C10.861 3.60614 11.0258 3.62147 11.187 3.67899C11.5605 3.81326 11.7986 4.0779 11.8791 4.48832C11.9267 4.72612 11.8974 4.96012 11.8315 5.19026C11.6814 5.72724 11.3444 6.09931 10.8757 6.35244L9.39987 3.60614H9.39983Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconRepresentLoyalty;
