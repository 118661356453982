/* eslint-disable import/prefer-default-export */
import { useShopConfig } from '../../../hooks/shopConfig';
import { useFormattedListingVariantOptionsData } from '../../../hooks/useListingVariantOptions';
import { getLabel } from '../../../util/helpers';
import { findOptionsForSelectFilter } from '../../../util/search';
import { useCurrentListing } from './useCurrentListing';

export const useVariantDetails = () => {
  const { currentListing: listing } = useCurrentListing();
  const { filters } = useShopConfig();
  const formattedVariantOptionsData = useFormattedListingVariantOptionsData(listing);

  const { condition } = listing.attributes.publicData;
  const conditionOptions = findOptionsForSelectFilter('condition', filters);
  const conditionValue = getLabel(conditionOptions, condition);

  return { ...formattedVariantOptionsData, Condition: conditionValue };
};
