import { isEmpty } from 'lodash';
import { Listing } from '../types/sharetribe/listing';
import { useShopConfig } from './shopConfig';
import { formatVariantOptionForDisplay } from '../util/shopifyHelpers';
import { Filter, FilterId } from '../types/filters/filters';
import { findOptionsForSelectFilter } from '../util/search';
import { getLabel } from '../util/helpers';

interface VariantOption {
  name: string;
  values: { key: string; label: string }[];
}

export const useListingVariantOptionsForDisplay = () => {
  const {
    variantOptions = [],
    customVariantDropdownOptions = [],
    customItemDetailsInputFields = [],
  } = useShopConfig();

  const customItemDetailsKeysToDisplay = customItemDetailsInputFields
    .filter((field: { shouldDisplay: boolean }) => field.shouldDisplay)
    .map((field: { key: string }) => field.key);

  const customVariantOptions = customVariantDropdownOptions
    .filter((option: VariantOption) => !isEmpty(option.values))
    .map((option: VariantOption) => option.name);

  const allVariantOptions = [
    ...variantOptions,
    ...customVariantOptions,
    ...customItemDetailsKeysToDisplay,
  ];

  return allVariantOptions;
};

export const getFormattedListingVariantOptionsData = (
  listing: Listing,
  customVariantDropdownOptions: { name: string; values: { key: string; label: string }[] }[],
  variantOptions: string[],
  sizeVariantOptionName: string,
  filters: Filter[]
): { [key: string]: string } => {
  const formatVariantOptionValueForDisplay = (variantName: string, variantValue: string) => {
    const customVariantOption = customVariantDropdownOptions.find(
      (option: { name: string }) => option.name === variantName
    );

    if (customVariantOption) {
      return getLabel(customVariantOption.values, variantValue);
    }

    if (variantName === sizeVariantOptionName) {
      const sizeOptions = findOptionsForSelectFilter(FilterId.Size, filters);
      return getLabel(sizeOptions, variantValue);
    }

    return variantValue;
  };

  return variantOptions.reduce((acc, variantOption) => {
    const variantValue = listing.attributes.publicData?.[variantOption];
    if (!variantValue) return acc;
    const formattedVariantName = formatVariantOptionForDisplay(variantOption);
    const formattedVariantValue = formatVariantOptionValueForDisplay(variantOption, variantValue);
    return { ...acc, [formattedVariantName]: formattedVariantValue };
  }, {});
};

export const useFormattedListingVariantOptionsData = (
  listing: Listing
): { [key: string]: string } => {
  const { customVariantDropdownOptions = [], sizeVariantOptionName, filters } = useShopConfig();
  const variantOptions = useListingVariantOptionsForDisplay();
  return getFormattedListingVariantOptionsData(
    listing,
    customVariantDropdownOptions,
    variantOptions,
    sizeVariantOptionName,
    filters
  );
};
